import React from 'react';
import {Link} from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';
import mapImage from '../../images/propertyDetails/map.jpg';
import './PropertyMap.scss';
import GoogleSingleMap from "../maps/google/single"
import { propTypes } from 'react-bootstrap/esm/Image';

const PropertyMap = (props) => {
	return (
		<div className='property-map' id="property-map">
			{props.address?
			<h3>{props.address.address} </h3>
			:""}
			<div className="map-wrap">
				{/* <img src={mapImage} alt="map" /> */}
				<GoogleSingleMap lat={props.lat} lng={props.lng}  height={props.height ? props.height : "500px"}/>
				{/* <div className="tags-wrap">
					<Link to='/' className="tag btn active"><span className="icon-tag"></span> Location</Link>
					<Link to='/' className="tag btn"><span className="icon-book"></span> Schools</Link>
					<Link to='/' className="tag btn"><span className="icon-spoon"></span> Amenities</Link>
				</div> */}
			</div>
		</div>
	)
}

export default PropertyMap