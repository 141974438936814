import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PlayVideo from "../PlayVideo/PlayVideo";
import { Link } from "gatsby";
import { useWindowSize } from "../../hooks/window-size";
import Slider from "react-slick";
import googleImg from "../../images/reviews/google.svg";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Banner.scss";
import "../BreadCrumbs/BreadCrumbs.scss";
import parse from 'html-react-parser';
import BannerSearch from "../Banner/BannerSearchForm";
import BannerSearchOverlay from "../Banner/BannerSearchOverlay";

import GenerateLink from "../common/site/GetLink"
import SiteBreadcrumbs from "../BreadCrumbs/site-breadcrumbs"
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import { scrollToElement } from "../common/site/functions";
import ElfsightWidget from "../Reviews/Elfsight/ElfsightWidget";

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  mobileFirst: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const Banner = (props) => {
  const [submitSearch, setsubmitSearch] = useState('');
  const [furnishval, setFurnishVal] = useState('');
  const [sqftVal, setSqftVal] = useState('');
  const [amenities, setAmenities] = useState('');
  const [parkingval, setParkingVal] = useState('');
  const [viewingval, setViewingVal] = useState('');
  const isStatic = props.layout === "Static"
  const [windowWidth] = useWindowSize();
  const [isPlay, setIsPlay] = useState(false);

  const playVideoHandler = () => {
    setIsPlay(true);
  };
  const [testimonials, setTestimonials] = useState([])
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }
      })// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  return (
    <div className={`awards-wrap inner-banner-wrap ${props.additionalClass?props.additionalClass:''}`}>
      <section
        className={`banner-wrap reviews-breadcrumb ${isStatic && "static-banner-wrap"}`}
        style={{
          backgroundImage: `url(${
            props.overlaybanner && props.overlaybanner.url ? props.overlaybanner.url : ""
          })`,
        }}
      >
        <div className={`banner-overlay ${(props.overlaybanner && props.overlaybanner.url) || props.layout === "Static" ? "" : "banner-gradient"}`}></div>
        <Container>
          <Row>
            <Col>
             
                {props.type=="area-details"?
                    <SiteBreadcrumbs  baselabel="Living in Dubai" baseurl="/about/living-in-dubai/" menulabel={props.pageTitle}/>  
                :
                <SiteBreadcrumbs/>
                }
                {/* <Breadcrumb className="breadcrumb-wrapper">
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">About</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">
                    Awards and Recognition
                  </Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="banner-content">
                  <h1>{props.title}</h1>
                  {props.content && parse(props.content)}
                  {props.search === true && (
                    <div className={`banner inner-banner-search ${props.bannerimage ? "" : "no-banner-img"}`}>
                      <BannerSearch viewingval={viewingval} furnishval={furnishval} sqftVal={sqftVal} parkingval={parkingval} submitSearch={submitSearch}/>
                    </div>
                  )}
                  {props.buttons && props.buttons.length > 0 && (
                    <div className="banner-ctas">
                      {props.buttons.map((item, index) => {

                        if (
                          item.CTA_Secondary_Link && (item.CTA_Secondary_Link.indexOf("#") === 0)
                        ) {
                          return (
                            <button
                              onClick={() => {
                                const element = document.getElementById(item.CTA_Secondary_Link.replace("#", ""));
                                if (element) {
                                  scrollToElement(element);
                                }
                              }}
                              className={
                                index === 0 ? "btn" : "btn btn-secondary"
                              }
                              key={index}
                            >
                              <span>
                                <span>{item.CTA_Label}</span>
                                <i className="icon-right"></i>
                              </span>
                            </button>
                          )
                        }

                        return (
                          <GenerateLink
                            link={item.CTA_Link?.Primary_URL}
                            class={index === 0 ? "btn" : "btn btn-secondary"}
                          >
                           <span>
                              <span>{item.CTA_Label}</span>
                              <i className="icon-right"></i>
                            </span>
                          </GenerateLink>
                        )
                      })}
                    </div>
                  )}
                  {props.reviews === true && (
                    <div className="review-wrap">
                      <div className="img-wrap">
                        <img src={googleImg} alt="google" />
                      </div>
                      <ElfsightWidget widgetID="2d63f214-8945-454a-b585-5d2bbe396d9b" />
                      {/* <span className="text-wrap">                       
                        Rated {parseFloat(rating_avg).toFixed(1)}/5 from {testimonial_count} Google Reviews
                      </span> */}
                    </div>
                  )}
                </div>
                {/* {bannerData.reviewerData ? (
                <div className="slider-wrap">
                  <Slider {...settings} className="btn-fix">
                    {bannerData.reviewerData.map((reviewer, index) => (
                      <div className="slide" key={index}>
                        <div className="img-wrap img-zoom">
                          <picture>
                            <source
                              media="(min-width: 1600px)"
                              srcSet={reviewer.reviewerImgDeskLg}
                            />
                            <source
                              media="(min-width: 992px)"
                              srcSet={reviewer.reviewerImgDesk}
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={reviewer.reviewerImgTab}
                            />
                            <img src={reviewer.reviewerImgMob} alt="" />
                          </picture>
                        </div>
                        <button
                          onClick={() => setIsPlay(true)}
                          className="video-wrap"
                        >
                          <i className="video-btn"></i>
                        </button>
                        <div className="reviewer-info">
                          <span className="reviewer-name">
                            {reviewer.reviewerName}
                          </span>
                          <span className="reviewer-type">
                            {reviewer.reviewerType}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : null} */}
            </Col>
          </Row>
        </Container>
        <PlayVideo
          isOpen={isPlay}
          autoplay
          videoId="V8ksSGxo6no"
          stopPlay={setIsPlay}
        />
      </section>
      {props.search === true && <BannerSearchOverlay  submitSearch={setsubmitSearch} furnishval={setFurnishVal} sqftVal={setSqftVal} amenitiesvalues={setAmenities} parkingval={setParkingVal} viewingval={setViewingVal}/>}

    </div>
  )
};

export default Banner;
