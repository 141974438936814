import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ContactCardNoStaff from "../../components/ContactSidebar/ContactSidebarNoStaff"
import ContactCard from "../../components/ContactSidebarCard/ContactSidebar"

import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"

import DetailMap from "../map/detail-map"
import "./AreaguidesDetail.scss"
import Sticky from "react-stickynode"

import parse from "html-react-parser"
import Faq from "../FaqList/Faq"
import { Link } from "gatsby"

const AreaguidesDetail = ({ areaguide }) => {
  const aboveIntro = areaguide?.Areaguide_Above_Intro
  const areaguideTile = areaguide?.Areaguide_Tile
  const belowIntro = areaguide?.Areaguide_Below_Intro
  const faqs = areaguide?.Areaguide_FAQ?.FAQ_List
  const areaname = areaguide?.Title.toLowerCase().replace(/\s+/g, "-")
  const staff = areaguide?.Select_Contact

  const waMsg = `${areaguide?.Title} - Hi there, I would like to know more about ${areaguide?.Title}`

  return (
    <div className="areaguides-detail-container">
      <Container>
        <Row>
          <Col md={12} lg={8} xl={8}>
            <div className="areaguides-detail-content">
              {aboveIntro?.Intro_Content_Above && aboveIntro?.Intro_Content_Above[0] &&
                <div className="above-content">
                  {parse(aboveIntro?.Intro_Content_Above)}
                  <GetGatsbyImage
                    image={aboveIntro?.Intro_Image_Above[0]}
                    fallbackalt={aboveIntro?.Intro_Image_Above[0]}
                  />
                </div>
              }

              <div className="areaguide-tile">
                {parse(areaguideTile?.Tile_Content)}
                <div className="btn-wrap">
                  {/* <a
                    className="btn"
                    href={`/property/for-sale/in-${areaname}/`}
                  >
                    Property for Sale in {areaguide?.Title}<i class="icon-right"></i>
                  </a>
                  <a
                    className="btn"
                    href={`/property/for-rent/in-${areaname}/`}
                  >
                    Property for Rent in {areaguide?.Title}<i class="icon-right"></i>
                  </a> */}
                  {areaguideTile.Tile_CTA &&
                      areaguideTile.Tile_CTA.map((item, index) => (
                        <a href={item?.CTA_Secondary_Link ? item?.CTA_Secondary_Link : item?.CTA_Link} className={"btn"}>
                          {item.CTA_Label}
                          <i className="icon-right"></i>
                        </a>
                  ))}
                </div>
              </div>

              <div className="below-content">
                {belowIntro?.Intro_Content_Below &&
                <>
                {parse(belowIntro?.Intro_Content_Below)}
                </>
                }
                {belowIntro?.Intro_Image_Below &&
                <GetGatsbyImage
                image={belowIntro?.Intro_Image_Below}
                fallbackalt={belowIntro?.Intro_Image_Below}
              /> 
                }
                
                {belowIntro?.Intro_Content_Below_Last &&
                <>
                {parse(belowIntro?.Intro_Content_Below_Last)}
                </>
                }
                <Faq faqs={faqs} noHeading />
              </div>
              <div className="stop-sticky"></div>
            </div>
          </Col>
          <Col lg={4} xl={4}>
            <div className="contactCard">
              {" "}
              <Sticky top=".header" bottomBoundary=".stop-sticky">
                {/* {staff ? <ContactCard staff={staff} /> : <ContactCardNoStaff />} */}
                <ContactCardNoStaff waMsg={waMsg} />
              </Sticky>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AreaguidesDetail
